import { FC, useRef, useState } from 'react';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { getBalance, isErrorResponse, IUserResponse, logoutUser } from 'api';
import { CHALLENGE_FORM_DATA_KEY } from 'consts';
import { useClickOutside, useLanguageUpdate } from 'hooks';
import {
  LogoutIcon,
  WalletIcon,
  CoinIcon,
  ArrowIconClosed,
  ArrowIconOpen,
  ProfileSmall,
} from 'icons';
import { prevState } from 'middleware/prevState';
import { SkeletonLoader, Typography } from 'ui-kit';

import { useStyle } from '../HeaderComponents.styles';

interface IUserCard {
  user: IUserResponse;
}

export const UserCard: FC<IUserCard> = ({ user }) => {
  const avatarRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const onToggleMenuClick = (): void => {
    setIsOpen((prevState) => !prevState);
  };

  useClickOutside(avatarRef, () => {
    setIsOpen(false);
  });
  const { t: translation } = useTranslation();
  const { classes } = useStyle();
  const navigate = useNavigate();
  const { updateLanguage } = useLanguageUpdate();

  const handleLogout = async (): Promise<void> => {
    await logoutUser();
    updateLanguage();
    localStorage.removeItem(CHALLENGE_FORM_DATA_KEY);
    mutate('api/user', {});
    mutate('api/challenges-leaderboard');
    mutate('api/predict-leaderboard');
  };
  const { data: balance, isValidating } = useSWR(
    () => (!isErrorResponse(user) && user?.id ? 'api/balance' : null),
    getBalance,
    {
      use: [prevState],
      refreshInterval: 5 * 60 * 1000,
    }
  );
  const balanceFormatted =
    balance?.balance % 1 == 0
      ? Math.floor(Number(balance?.balance))
      : balance?.balance;
  const { Text } = Typography;
  return (
    <>
      {isValidating ? (
        <SkeletonLoader className={classes.placeholderContainer}>
          <div className={classes.balanceIconPlaceholder} />
          <div className={classes.balanceSkeleton} />
          <div className={classes.balanceSkeleton} />
        </SkeletonLoader>
      ) : (
        <>
          <div
            className={classes.balanceContainer}
            onClick={() => navigate('/profile')}
          >
            <CoinIcon className={classes.balanceIcon} />
            <span className={classes.balance}>{balanceFormatted ?? 0} GC</span>
          </div>
          <div
            className={classes.balanceContainer}
            onClick={() => navigate('/profile')}
          >
            <Text variant="b2" color="primary">
              {`≈ ${(balance?.balance / 10).toFixed(1)} RUB`}
            </Text>
          </div>
        </>
      )}
      <div className={classes.flexAICenter}>
        <div className={classes.userCardContainer}>
          <div
            onClick={onToggleMenuClick}
            ref={avatarRef}
            className={
              isOpen ? clsx(classes.userCard, classes.open) : classes.userCard
            }
          >
            <div className={classes.image}>
              <button>
                <img
                  alt="avatar"
                  className={clsx(!user?.avatarUrl && classes.avatar)}
                  src={user?.avatarUrl ?? '/images/header/default-avatar.png'}
                />
              </button>
            </div>
            <div className={classes.userName}>{user?.username}</div>
            <div className={classes.arrowContainer}>
              <ArrowIconClosed
                className={clsx(
                  isOpen ? classes.arrowIconOpen : classes.arrowIcon
                )}
              />
              <ArrowIconOpen
                className={clsx(
                  isOpen ? classes.arrowIcon : classes.arrowIconOpen
                )}
              />
            </div>
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                className={classes.menu}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Link to="/profile" className={classes.menuItem}>
                  <ProfileSmall className={classes.menuIcon} />
                  <Text variant="b4">{translation('header.user.profile')}</Text>
                </Link>
                <Link
                  to="/home"
                  onClick={handleLogout}
                  className={classes.menuItem}
                >
                  <LogoutIcon className={classes.menuIcon} />
                  <Text variant="b4">{translation('header.user.logout')}</Text>
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};
