import type { FC } from 'react';

import type { ICommonIconProps } from './types';

export const HomeIcon: FC<ICommonIconProps> = ({
  width = 16,
  height = 16,
  viewBox = '0 0 16 16',
  className = '',
  style,
}) => (
  <svg
    fill="none"
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M15.5 7.02344V14.25C15.4995 14.4756 15.4383 14.6969 15.3228 14.8907C15.2073 15.0845 15.0419 15.2437 14.8438 15.3516C14.6621 15.4517 14.4575 15.5029 14.25 15.5H10.5C10.3343 15.5 10.1753 15.4342 10.0581 15.3169C9.94086 15.1997 9.87502 15.0408 9.87502 14.875V11.125C9.87502 10.9592 9.80917 10.8003 9.69196 10.6831C9.57475 10.5658 9.41578 10.5 9.25002 10.5H6.75002C6.58426 10.5 6.42528 10.5658 6.30807 10.6831C6.19086 10.8003 6.12502 10.9592 6.12502 11.125V14.875C6.12502 15.0408 6.05917 15.1997 5.94196 15.3169C5.82475 15.4342 5.66578 15.5 5.50002 15.5H1.75002C1.57269 15.5007 1.39728 15.4634 1.23557 15.3906C1.07387 15.3178 0.929615 15.2113 0.812515 15.0781C0.609309 14.838 0.498491 14.5333 0.500016 14.2188V7.02344C0.500071 6.85 0.536218 6.67847 0.606158 6.51976C0.676098 6.36105 0.7783 6.21863 0.906266 6.10156L7.15627 0.421875C7.3867 0.211061 7.6877 0.0941467 8.00002 0.0941467C8.31233 0.0941467 8.61333 0.211061 8.84377 0.421875L15.0938 6.10156C15.2217 6.21863 15.3239 6.36105 15.3939 6.51976C15.4638 6.67847 15.5 6.85 15.5 7.02344Z"
      fill={'currentColor'}
    />
  </svg>
);
