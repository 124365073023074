import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { ModalContext } from 'components';
import { Button, Typography } from 'ui-kit';

import { getUserGeo } from '../../../api';
import {
  AdsBlock,
  BetaTestResult,
  Header,
  VideoBlock,
  Faq,
  Streamers,
  DiscordCard,
} from './components';
import { TwitterCard } from './components/TwitterCard';
import { useStyle } from './Home.styles';

export function Home(): JSX.Element {
  const { t: translation } = useTranslation();
  const { data: user, error } = useSWR('api/user');
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isLogin = !error && !!user?.id;

  const { classes } = useStyle();

  const {
    loginModal: { openLogin },
  } = useContext(ModalContext);

  const { Headline } = Typography;

  return (
    <>
      {/*<Header />*/}
      <VideoBlock />
      {/*<AdsBlock />*/}
      {/*geo?.country !== 'RU' &&*/ <Streamers />}
      {/*<Faq />*/}
      {/*
      <div className={classes.leaderboardContainer}>
        <TwitterCard />
        <DiscordCard />
      </div>
      */}
      {/* <BetaTestResult /> */}
      {/*!isLogin && (
        <div className={classes.footerContainer}>
          <div className={classes.imgContainer}>
            <Headline>{translation('home.footer.text')}</Headline>
            <Button color="brand" buttonSize="xl" onClick={openLogin}>
              {translation('home.footer.btn')}
            </Button>
          </div>
        </div>
      )*/}
    </>
  );
}
